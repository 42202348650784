.loader {
  width: 40px;
  height: 40px;
  border: 4px solid transparent;
  border-top: 4px solid var(--admin-primary, #0f2752);
  border-bottom: 4px solid var(--admin-primary, #0f2752);
  border-radius: 50%;
  animation: loader-spin 640ms
    var(--admin-transition-easing, cubic-bezier(0.4, 0, 0.2, 1)) infinite;
}

@keyframes loader-spin {
  100% {
    transform: rotate(360deg);
  }
}
