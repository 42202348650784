.inscriptions {
  display: flex;
  flex-direction: column;
  margin: 25px auto;
  padding: 15px;
  background-color: var(--admin-lightest-grey);
}

.inscriptions__dl-csv {
  margin: 0 0 0 auto;
  color: #fff;
}

.inscriptions__dl-csv:last-child {
  margin-top: 15px;
}

.inscriptions__dl-csv,
.inscriptions__dl-csv:hover {
  background-color: var(--admin-primary);
}
