:root {
  --admin-grey: #9d9d9d;
  --admin-lightest-grey: #f5f5f5;
  --admin-primary: #0f2752;

  --admin-green: #4caf50;
  --admin-red: #d50000;
  --admin-yellow: #ffa000;

  --admin-content-width: 1400px;

  --admin-focus-ring: 0 0 0 3px rgba(0, 175, 244, 0.5);
  --admin-shadow-small: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  --admin-transition-easing: cubic-bezier(0.4, 0, 0.2, 1);
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.app {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
