.button {
  contain: content;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  box-sizing: border-box;
  padding: 8px 15px;
  margin: 3px;
  min-width: 64px;
  outline: 0;
  border: 0;
  background-color: transparent;
  color: var(--admin-primary);
  fill: currentColor;
  font-size: 15px;
  font-family: inherit;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1;
  cursor: pointer;
  user-select: none;
  justify-content: center;
  text-decoration: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 240ms cubic-bezier(0.4, 0, 0.2, 1);
}

.button::-moz-focus-inner {
  border: none;
}

.button:focus {
  outline: none;
  box-shadow: var(--admin-focus-ring);
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.button[disabled] {
  background-color: var(--admin-grey);
  color: var(--admin-primary);
  fill: var(--admin-primary);
}

.button__label {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 3px;
  letter-spacing: 0.35px;
  font-weight: 700;
}
