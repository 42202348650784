.contact {
  display: flex;
  flex-direction: column;
  margin: 25px auto;
  padding: 15px;
  background-color: var(--admin-lightest-grey);
}

.contact__tips {
  margin-top: 10px;
  padding: 15px;
  background-color: #fff;
}

.contact__tips-title {
  font-size: 22px;
  font-weight: 700;
}

.contact__buttons {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 10px 0;
  background-color: var(--admin-lightest-grey);
}

.contact__add-subject {
  color: #fff;
}

.contact__add-subject,
.contact__add-subject:hover {
  background-color: var(--admin-primary);
}
