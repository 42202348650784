.news {
  display: flex;
  flex-direction: column;
  margin: 25px auto;
  padding: 15px;
  background-color: var(--admin-lightest-grey);
}

.news__top {
  padding: 10px;
  font-style: italic;
  text-align: center;
  background-color: #fff;
}

.news__tips {
  margin-top: 10px;
  padding: 15px;
  background-color: #fff;
}

.news__tips-title {
  font-size: 22px;
  font-weight: 700;
}

.news__buttons {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 10px 0;
  background-color: var(--admin-lightest-grey);
}

.news__add-news {
  color: #fff;
}

.news__add-news,
.news__add-news:hover {
  background-color: var(--admin-primary);
}
