.subject-form {
  display: flex;
  flex-direction: column;
}

.subject-form__label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.subject-form__label--checkbox {
  flex-direction: row;
  align-items: center;
}

.subject-form__input {
  padding: 5px 10px;
  color: var(--admin-primary);
  font-size: 15px;
  font-family: inherit;
  background-color: var(--admin-lightest-grey);
  border: 2px solid transparent;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: box-shadow 0.16s var(--admin-transition-easing);
}

.subject-form__label--checkbox .subject-form__input {
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
}

.subject-form__input:required:invalid,
.subject-form__input:invalid {
  border: 2px solid var(--admin-red);
}

.subject-form__input:required:valid {
  border: 2px solid var(--admin-green);
}

.subject-form__buttons {
  display: flex;
  margin-top: 25px;
}

.subject-form__button--cancel,
.subject-form__button--cancel:hover {
  margin-right: 25px;
  color: #fff;
  background-color: var(--admin-red);
}

.subject-form__button--confirm,
.subject-form__button--confirm:hover {
  margin-left: auto;
  color: #fff;
  background-color: var(--admin-green);
}
