.top-bar {
  height: 60px;
  box-shadow: var(--admin-shadow-small);
  padding: 0 15px;
}

.top-bar__inner {
  width: 100%;
  max-width: var(--admin-content-width);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 0 auto;
}

.top-bar__logo {
  flex: 0 0 70px;
}
