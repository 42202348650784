.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal__inner {
  position: relative;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #fff;
}

.modal__title {
  color: var(--admin-primary);
  text-transform: uppercase;
  font-weight: 700;
}

.modal__content {
  padding-top: 25px;
}

.modal__buttons {
  display: flex;
  margin-top: 25px;
}

.modal__button--cancel,
.modal__button--cancel:hover {
  margin-right: 25px;
  color: #fff;
  background-color: var(--admin-red);
}

.modal__button--confirm,
.modal__button--confirm:hover {
  margin-left: auto;
  color: #fff;
  background-color: var(--admin-green);
}
