.list {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.list__row {
  background-color: #fff;
}

.list__head-cell {
  padding: 7px 15px;
  text-align: left;
}

.list__head-cell:last-of-type {
  text-align: center;
}

.list__cell {
  padding: 3px 15px;
}

.list__cell--buttons {
  text-align: center;
}

.list__cell--buttons > button {
  vertical-align: middle;
}

.list__row:not(:last-of-type) .list__cell {
  border-bottom: 2px solid var(--admin-lightest-grey);
}

.list__cell:not(:last-of-type) {
  border-right: 2px solid var(--admin-lightest-grey);
}

.list__button {
  min-width: auto;
  padding: 0;
}

.list__icon {
  width: 18px;
  height: 18px;
}
