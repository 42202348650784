.menu {
  display: flex;
  justify-content: center;
}

.menu__item--current {
  color: #fff;
  background-color: var(--admin-primary);
}

.menu__item--current:hover {
  background-color: var(--admin-primary);
  opacity: 0.9;
}
