.admin {
  height: 100%;
  width: 100%;
}

.admin__inner {
  width: 100%;
  max-width: var(--admin-content-width);
  margin: 0 auto;
  padding: 25px 15px;
}

@media (min-width: calc(--admin-content-width + 30px)) {
  .admin__inner {
    padding: 25px 0;
  }
}
